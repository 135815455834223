import React, { useCallback, useMemo, useState } from 'react';
import { Flex, Heading, Hide, Show, useToast } from '@chakra-ui/react';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { ButtonProps } from 'ts/common/components/gallery';
import { useTranslate } from 'ts/common/hooks';
import { useClientBookingExperience } from 'client_react/booking/ClientBookingExperience';
import { ROUTE } from 'client_react/booking/common';
import PaymentOptions from 'client_react/booking/components/PaymentOptions';
import PaymentSummary from 'client_react/booking/components/PaymentSummary';
import ResponsiveButtonGroup from 'client_react/booking/components/ResponsiveButtonGroup';
import { isBookingSessionError } from 'client_react/booking/useBookingSession';

const PaymentForm = ({
    paymentAmountType,
    setPaymentAmountType
}: {
    paymentAmountType: 'full' | 'retainer';
    setPaymentAmountType: (x: string) => void;
}) => {
    const { isPending, navigateWithSession, submitPayment } = useClientBookingExperience();

    const t = useTranslate('booking.payment');
    const stripe = useStripe();
    const elements = useElements();

    const errorToast = useToast({
        status: 'error'
    });

    const [isReadyToPay, setIsReadyToPay] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handlePaymentFormReady = useCallback(() => {
        setIsReadyToPay(true);
    }, []);

    const handleSubmit = useCallback(
        async (event: React.FormEvent) => {
            event.preventDefault();

            if (!stripe || !elements) {
                return;
            }

            setIsSubmitting(true);

            const { error: elementsError } = await elements.submit();

            if (elementsError) {
                setIsSubmitting(false);
                errorToast({
                    title: elementsError.message
                });
                return;
            }

            const { error: paymentMethodError, paymentMethod } = await stripe.createPaymentMethod({
                elements
            });

            if (paymentMethodError) {
                setIsSubmitting(false);
                errorToast({
                    title: paymentMethodError.message
                });
                return;
            }

            const response = await submitPayment({
                paymentAmountType,
                paymentMethodId: paymentMethod.id
            });

            setIsSubmitting(false);

            if (isBookingSessionError(response)) {
                errorToast({
                    title: response ? response.detail ?? String(response) : t('paymentError')
                });
            } else {
                navigateWithSession(ROUTE.CONFIRMATION);
            }
        },
        [elements, errorToast, navigateWithSession, paymentAmountType, stripe, submitPayment, t]
    );

    const buttons = useMemo<Array<ButtonProps>>(
        () => [
            {
                text: t('back'),
                variant: 'outline primary',
                onClick: () => {
                    navigateWithSession(ROUTE.CONTACT_INFORMATION);
                }
            },
            {
                text: t('bookSession'),
                variant: 'primary',
                isDisabled: !stripe || !elements || !isReadyToPay || isPending || isSubmitting,
                isLoading: isSubmitting,
                type: 'submit'
            }
        ],
        [elements, isPending, isReadyToPay, isSubmitting, navigateWithSession, stripe, t]
    );

    return (
        <Flex
            as="form"
            alignItems="stretch"
            flexDirection="column"
            gap="40px"
            onSubmit={handleSubmit}
        >
            <Flex gap="24px 64px">
                <Flex flex="1 1 auto" flexDirection="column" gap="24px 64px">
                    <PaymentOptions
                        paymentAmountType={paymentAmountType}
                        setPaymentAmountType={setPaymentAmountType}
                    />
                    <Hide above="lg">
                        <PaymentSummary paymentAmountType={paymentAmountType} />
                    </Hide>
                    <Flex flexDirection="column" gap="16px">
                        <Heading as="h3" size="lg" margin={0}>
                            {t('billingInformation')}
                        </Heading>
                        <PaymentElement onReady={handlePaymentFormReady} />
                    </Flex>
                </Flex>
                <Show above="lg">
                    <PaymentSummary paymentAmountType={paymentAmountType} />
                </Show>
            </Flex>
            <ResponsiveButtonGroup buttons={buttons} marginTop="32px" />
        </Flex>
    );
};

export default PaymentForm;
