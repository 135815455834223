import React from 'react';
import { Link, Text } from '@chakra-ui/react';
import { useBreakpoint, useTranslate } from 'ts/common/hooks';

const ReCaptchaDisclaimer = () => {
    const t = useTranslate('booking.dateAndTimePage');
    const isLargeScreen = useBreakpoint();

    return (
        <Text
            position={isLargeScreen ? 'absolute' : 'relative'}
            top="105%"
            left="0"
            textAlign="center"
            box-sizing="border-box"
            width="100%"
            marginTop={isLargeScreen ? '0' : '30px'}
        >
            {t('protectedByReCaptcha')}
            <Link
                href="https://policies.google.com/privacy"
                isExternal
                color="brand.primary"
                _focus={{
                    boxShadow: 'none'
                }}
                _focusVisible={{
                    boxShadow: 'outline'
                }}
                _visited={{
                    color: 'brand.primary',
                    '&:hover': {
                        color: 'brand.secondary'
                    }
                }}
                _hover={{
                    textDecoration: 'none',
                    color: 'brand.secondary',
                    '&:visited': {
                        color: 'brand.secondary'
                    }
                }}
            >
                {t('policy')}
            </Link>
            {t('and')}
            <Link
                href="https://policies.google.com/terms"
                isExternal
                color="brand.primary"
                _focus={{
                    boxShadow: 'none'
                }}
                _focusVisible={{
                    boxShadow: 'outline'
                }}
                _visited={{
                    color: 'brand.primary',
                    '&:hover': {
                        color: 'brand.secondary'
                    }
                }}
                _hover={{
                    textDecoration: 'none',
                    color: 'brand.secondary',
                    '&:visited': {
                        color: 'brand.secondary'
                    }
                }}
            >
                {t('termsOfService')}
            </Link>
            {t('apply')}
        </Text>
    );
};

export default ReCaptchaDisclaimer;
