import { useCallback, useEffect, useState } from 'react';
import { FetchMethod, type IFetchHookOptions, type IFetchHookResponse } from 'ts/common/hooks';
import { API } from 'client_react/booking/common';
import { IBookingSession } from 'client_react/booking/types';
import { useClientApiFetch } from 'client_react/bootstrap';

interface BookingSessionError {
    detail?: string;
    status: number;
}

export function isBookingSessionError(
    error: IFetchHookResponse<IBookingSession | BookingSessionError>
): error is BookingSessionError {
    return !!error && error.status >= 400;
}

export default function useBookingSession(bookingSessionId: string | null) {
    const [bookingSession, setBookingSession] = useState<IFetchHookResponse<IBookingSession>>(null);
    const [error, setError] = useState<IFetchHookResponse<BookingSessionError>>(null);

    const { performFetch: getBookingSession, loading: isGetting } = useClientApiFetch<
        IBookingSession | BookingSessionError
    >(`${API.BOOKING_SESSION}/${bookingSessionId}`, {
        defer: true,
        method: FetchMethod.GET
    });

    const { performFetch: postBookingSession, loading: isPosting } = useClientApiFetch<
        IBookingSession | BookingSessionError
    >(API.BOOKING_SESSION, {
        defer: true,
        method: FetchMethod.POST
    });

    const { performFetch: patchBookingSession, loading: isPatching } = useClientApiFetch<
        IBookingSession | BookingSessionError
    >(`${API.BOOKING_SESSION}/${bookingSessionId}`, {
        defer: true,
        method: FetchMethod.PATCH
    });

    const { performFetch: postBookingSessionPayment, loading: isPostingPayment } =
        useClientApiFetch<IBookingSession | BookingSessionError>(
            `${API.BOOKING_SESSION}/${bookingSessionId}/payment`,
            {
                defer: true,
                method: FetchMethod.POST
            }
        );

    const handleApiFetch = useCallback(
        (
            apiMethod: (
                options?: IFetchHookOptions
            ) => Promise<IFetchHookResponse<IBookingSession | BookingSessionError>>
        ) => {
            return async (data?: IFetchHookOptions['data']) => {
                const response = await apiMethod({ data });

                if (isBookingSessionError(response)) {
                    setError(response);
                } else {
                    setBookingSession(response);
                }

                return response;
            };
        },
        []
    );

    useEffect(() => {
        // Fetch the booking session if we haven't already
        if (bookingSessionId && !bookingSession) {
            handleApiFetch(getBookingSession)();
        }
    }, [bookingSession, bookingSessionId, getBookingSession, handleApiFetch]);

    const createBookingSession = useCallback(
        (data: IFetchHookOptions['data']) => handleApiFetch(postBookingSession)(data),
        [handleApiFetch, postBookingSession]
    );

    const updateBookingSession = useCallback(
        (data: IFetchHookOptions['data']) => handleApiFetch(patchBookingSession)(data),
        [handleApiFetch, patchBookingSession]
    );

    const submitPayment = useCallback(
        (data: IFetchHookOptions['data']) => handleApiFetch(postBookingSessionPayment)(data),
        [handleApiFetch, postBookingSessionPayment]
    );

    return {
        isPending: isGetting || isPosting || isPatching || isPostingPayment,
        error,
        bookingSession,
        createBookingSession,
        updateBookingSession,
        submitPayment
    };
}
