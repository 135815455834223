import React from 'react';
import { Divider, Flex, Heading, Text } from '@chakra-ui/react';
import { useTranslate } from 'ts/common/hooks';
import { useClientBookingExperience } from 'client_react/booking/ClientBookingExperience';

const PaymentSummary = ({ paymentAmountType }: { paymentAmountType: 'full' | 'retainer' }) => {
    const { bookingSession } = useClientBookingExperience();
    const t = useTranslate('booking.payment');

    if (!bookingSession) {
        return null;
    }

    return (
        <Flex
            background="gray.25"
            flex={{ base: '0 1 auto', lg: '0 1 350px' }}
            flexDirection="column"
            padding="32px 24px"
        >
            <Heading as="h3" size="lg" margin={0}>
                {t('paymentSummary')}
            </Heading>
            <Divider borderColor="gray.300" margin="24px 0" />
            <Flex justifyContent="space-between">
                <Text textTransform="uppercase">{t('paymentSummarySubtotal')}</Text>
                <Text aria-label={t('paymentSummarySubtotal')}>
                    {paymentAmountType === 'full'
                        ? bookingSession.price
                        : bookingSession.retainerPaymentAmount}
                </Text>
            </Flex>
            <Flex justifyContent="space-between">
                <Text textTransform="uppercase">
                    {bookingSession.salesTaxTitle || t('paymentSummaryTax')}
                </Text>
                <Text aria-label={bookingSession.salesTaxTitle || t('paymentSummaryTax')}>
                    {paymentAmountType === 'full'
                        ? bookingSession.salesTaxForPrice
                        : bookingSession.salesTaxForRetainerPaymentAmount}
                </Text>
            </Flex>
            <Divider borderColor="gray.300" margin="24px 0" />
            <Flex color="brand.primary" justifyContent="space-between">
                <Heading as="h3" size="lg" margin={0} textTransform="uppercase">
                    {t('paymentSummaryTotal')}
                </Heading>
                <Heading as="h3" size="lg" margin={0} aria-label={t('paymentSummaryTotal')}>
                    {paymentAmountType === 'full'
                        ? bookingSession.priceWithSalesTax
                        : bookingSession.retainerPaymentAmountWithSalesTax}
                </Heading>
            </Flex>
        </Flex>
    );
};

export default PaymentSummary;
