import React, { useEffect, useMemo } from 'react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { Outlet, useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { withI18NextTranslate } from 'sp-ui';
import { getTheme } from 'ts/common/components/gallery';
import { IFetchHookResponse, useQuery } from 'ts/common/hooks';
import Header from 'client_react/booking/Header';
import PageContainer from 'client_react/booking/PageContainer';
import { QUERY } from 'client_react/booking/common';
import Layout from 'client_react/booking/components/Layout';
import { IBookingSessionAvailability, IBookingSessionType } from 'client_react/booking/types';
import useBookingSession from 'client_react/booking/useBookingSession';
import { useBootstrapData, useClientApiFetch, withBootstrapData } from 'client_react/bootstrap';

export type ClientBookingData = ReturnType<typeof useBookingSession> & {
    bookingSessionType: IFetchHookResponse<IBookingSessionType>;
    bookingSessionTypeAvailability: IFetchHookResponse<IBookingSessionAvailability>;
};

const App = withBootstrapData(() => {
    const navigate = useNavigate();
    const { translations } = useBootstrapData();
    const PageContainerWithTranslations = useMemo(
        () =>
            withI18NextTranslate<{ children: React.ReactNode }>(
                ({ children }: { children: React.ReactNode }) => (
                    <PageContainer>{children}</PageContainer>
                ),
                translations
            ),
        [translations]
    );

    const location = useLocation();

    const query = useQuery();
    const queryBookingSessionId = query.get(QUERY.BOOKING_SESSION_ID);

    const { sessionTypeSlug, bookingSessionId: paramBookingSessionId } = useParams();

    const bookingSessionId = location.pathname.startsWith('/session')
        ? paramBookingSessionId ?? null
        : queryBookingSessionId ?? null;

    const {
        bookingSession,
        createBookingSession,
        updateBookingSession,
        submitPayment,
        isPending,
        error
    } = useBookingSession(bookingSessionId);

    const { response: bookingSessionType, performFetch: fetchSessionType } =
        useClientApiFetch<IBookingSessionType>('booking-session-type', {
            defer: true
        });

    const currentDate = new Date().toISOString().split('T')[0];

    const { response: bookingSessionTypeAvailability, performFetch: fetchAvailability } =
        useClientApiFetch<IBookingSessionAvailability>('', {
            defer: true
        });

    useEffect(() => {
        if (sessionTypeSlug) {
            fetchSessionType({ url: sessionTypeSlug });
        }
    }, [fetchSessionType, sessionTypeSlug]);

    useEffect(() => {
        if (bookingSessionType?.publicId) {
            fetchAvailability({
                url: `booking-session-type/${bookingSessionType?.publicId}/availability`
            });
        }
    }, [bookingSessionType, currentDate, fetchAvailability]);

    useEffect(() => {
        if (
            (!bookingSessionId && !sessionTypeSlug) ||
            (bookingSessionId && error?.status === 404) ||
            (sessionTypeSlug && bookingSessionType?.status === 404)
        ) {
            navigate('/not-found');
        }
    }, [
        bookingSession,
        bookingSessionId,
        bookingSessionType,
        error,
        navigate,
        queryBookingSessionId,
        sessionTypeSlug
    ]);

    const brandTheme = bookingSession?.brandTheme ?? bookingSessionType?.brandTheme;

    const theme = useMemo(
        () => (brandTheme ? extendTheme(getTheme(brandTheme)) : undefined),
        [brandTheme]
    );

    const clientBookingData: ClientBookingData = useMemo(
        () => ({
            bookingSessionType,
            bookingSessionTypeAvailability,
            bookingSession,
            createBookingSession,
            updateBookingSession,
            submitPayment,
            isPending,
            error
        }),
        [
            bookingSessionType,
            bookingSessionTypeAvailability,
            bookingSession,
            createBookingSession,
            updateBookingSession,
            submitPayment,
            isPending,
            error
        ]
    );

    // something more elegant would be nice
    if (!theme) {
        return null;
    }

    return (
        <ChakraProvider resetCSS={false} theme={theme}>
            <PageContainerWithTranslations>
                <Header
                    brandName={bookingSession?.brandName ?? bookingSessionType?.brandName ?? ''}
                />
                <Layout>
                    <Outlet context={clientBookingData} />
                </Layout>
            </PageContainerWithTranslations>
        </ChakraProvider>
    );
});

export function useClientBookingData() {
    return useOutletContext<ClientBookingData>();
}

export default App;
