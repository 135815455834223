import React, { useMemo, useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import DatePicker from 'ts/common/components/date-picker/DatePicker';
import { ButtonProps } from 'ts/common/components/gallery';
import { useTranslate } from 'ts/common/hooks';
import { IBookingSessionType } from 'client_react/booking/types';
import ReCaptchaDisclaimer from './components/ReCaptchaDisclaimer';
import ResponsiveButtonGroup from './components/ResponsiveButtonGroup';
import TimePicker from './components/TimePicker';
import { getBrowserTimeZone } from './helpers';

type PanelType = 'description' | 'date' | 'time';

type Props = {
    availabilities: { [date: string]: string[] };
    bookingSessionType: IBookingSessionType;
    hideDatePicker?: boolean;
    selectedDate?: Date;
    selectedTimeslot?: string;
    timeslots: string[];
    onSubmit: () => void;
    onTimeslotChange: (timeslot: string) => void;
    onDateChange: (value: Date | null) => void;
};

const MobileDateAndTimePage = ({
    availabilities,
    bookingSessionType,
    hideDatePicker,
    onSubmit,
    selectedDate,
    selectedTimeslot,
    onDateChange,
    onTimeslotChange,
    timeslots
}: Props) => {
    const t = useTranslate('booking.dateAndTimePage');
    const today = new Date();
    const [activePanel, setActivePanel] = useState<PanelType>('description');

    const buttons = useMemo<Record<PanelType, Array<ButtonProps>>>(
        () => ({
            description: [
                { text: 'Continue', onClick: () => setActivePanel('date'), variant: 'primary' }
            ],
            date: [
                {
                    text: 'Back',
                    onClick: () => setActivePanel('description'),
                    variant: 'outline primary'
                },
                {
                    text: 'Continue',
                    isDisabled: !selectedDate,
                    onClick: () => setActivePanel('time'),
                    variant: 'primary'
                }
            ],
            time: [
                {
                    text: 'Back',
                    onClick: () => setActivePanel('date'),
                    variant: 'outline primary'
                },
                {
                    text: 'Continue',
                    isDisabled: !selectedTimeslot,
                    onClick: onSubmit,
                    variant: 'primary'
                }
            ]
        }),
        [onSubmit, selectedDate, selectedTimeslot]
    );

    return (
        <Flex flexDirection="column">
            {activePanel === 'description' && (
                <>
                    <Text>{bookingSessionType.description}</Text>
                    <ReCaptchaDisclaimer />
                </>
            )}
            {activePanel === 'date' && (
                <Box w={['100%', '100%', '430px']} padding={['10px', '20px']}>
                    {!hideDatePicker ? (
                        <DatePicker
                            inline
                            disableUnavailableDays
                            onChange={onDateChange}
                            min={today}
                            value={selectedDate ? selectedDate : null}
                            availabilities={availabilities}
                        />
                    ) : (
                        <Text paddingTop="10px" fontSize="16px" fontFamily="open-sans, sans-serif">
                            {t('noAvailabilities')}
                        </Text>
                    )}
                </Box>
            )}
            {activePanel === 'time' && (
                <Flex direction="column">
                    {selectedDate && (
                        <Flex alignItems="flex-start" direction="column" paddingTop="4px">
                            <Text
                                fontSize="16px"
                                fontWeight={600}
                                fontFamily="open-sans, sans-serif"
                            >
                                {new Date(selectedDate).toLocaleDateString([], {
                                    weekday: 'long',
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                })}
                            </Text>
                            <Text
                                fontSize="12px"
                                fontFamily="open-sans, sans-serif"
                                fontWeight={400}
                            >
                                {getBrowserTimeZone()}
                            </Text>
                        </Flex>
                    )}

                    <TimePicker
                        selectedTimeslot={selectedTimeslot}
                        onTimeslotChange={onTimeslotChange}
                        timeslots={timeslots}
                    />
                </Flex>
            )}

            <ResponsiveButtonGroup buttons={buttons[activePanel]} />
        </Flex>
    );
};

export default MobileDateAndTimePage;
