import type { Theme } from '@emotion/react';
import type { Appearance, StripeElementsOptions } from '@stripe/stripe-js';
import moment from 'moment';

export const getStripeAppearance = (theme: Theme): Appearance => {
    return {
        rules: {
            '.Checkbox': {
                color: theme.isLightColorScheme ? theme.colors.gray[500] : theme.colors.white,
                fontSize: '14px'
            },
            '.Input:focus': {
                borderColor: theme.colors.brand.primary,
                borderWidth: '2px',
                boxShadow: 'none'
            },
            '.Label': {
                color: theme.isLightColorScheme ? theme.colors.gray[500] : theme.colors.white,
                fontSize: '14px'
            }
        },
        theme: theme.isLightColorScheme ? 'stripe' : 'night',
        variables: {
            colorPrimary: theme.colors.brand.primary,
            fontFamily: 'open-sans, sans-serif'
        }
    };
};

export const getStripeFonts = (staticUrl: string): StripeElementsOptions['fonts'] => [
    {
        family: 'open-sans',
        src: `url(${staticUrl}/common/font/OpenSans-Regular.woff)`,
        weight: '400'
    },
    {
        family: 'open-sans',
        src: `url(${staticUrl}/common/font/OpenSans-SemiBold.woff)`,
        weight: '600'
    },
    {
        family: 'open-sans',
        src: `url(${staticUrl}/common/font/OpenSans-Bold.woff)`,
        weight: '700'
    }
];

// Our translation function replaces special characters with HTML escape characters,
// so we need a way to replace them back to display the correct text in React.
export const replaceHtmlEscapeCharacters = (text: string): string => {
    const span = document.createElement('span');
    span.innerHTML = text;
    return span.innerText;
};

export const getBrowserTimeZone = () => {
    const gmtOffset = moment().format('Z');
    const timeZoneName = Intl.DateTimeFormat()
        .resolvedOptions()
        .timeZone.replace('_', ' ')
        .replace('/', ', ');
    return `(GMT${gmtOffset}) ${timeZoneName}`;
};

export const convertTimeToMinutes = (time: Date): number => {
    return time.getHours() * 60 + time.getMinutes();
};
