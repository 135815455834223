import React, { useMemo } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { Link, Phone } from 'react-feather';
import { useTranslate } from 'ts/common';
import { CalendarIcon, ClockIcon, CurrencyIcon, LocationIcon } from 'ts/common/components/icons';
import { useTranslatedTimeUnits } from 'ts/common/hooks/useTranslatedTimeUnits';
import { IBookingSession, IBookingSessionType } from 'client_react/booking/types';

const BookingSessionSummary = ({
    bookingSession,
    bookingSessionType,
    isConfirmation
}: {
    bookingSession: Nullable<IBookingSession>;
    bookingSessionType: Nullable<IBookingSessionType>;
    isConfirmation?: boolean;
}) => {
    const t = useTranslate('booking');

    const durationString = useTranslatedTimeUnits(
        bookingSessionType?.durationUnit ?? null,
        bookingSessionType?.duration
    );

    const { locationIcon, locationValue } = useMemo(() => {
        let locationType;

        if (bookingSession) {
            if (bookingSession.address) {
                locationType = 'address';
            } else if (bookingSession.phoneNumber) {
                locationType = 'phone';
            } else if (bookingSession.url) {
                locationType = 'url';
            }
        }

        locationType = locationType ?? bookingSessionType?.locationType;

        switch (locationType) {
            case 'phone':
                return {
                    locationIcon: <Phone viewBox="-2 -2 28 28" />,
                    locationValue: bookingSession?.phoneNumber ?? bookingSessionType?.phoneNumber
                };
            case 'url':
                return {
                    locationIcon: <Link viewBox="-2 -2 28 28" />,
                    locationValue: bookingSession?.url ?? bookingSessionType?.url
                };
            default:
                return {
                    locationIcon: <LocationIcon />,
                    locationValue: bookingSession?.address ?? bookingSessionType?.address
                };
        }
    }, [
        bookingSession,
        bookingSessionType?.address,
        bookingSessionType?.locationType,
        bookingSessionType?.phoneNumber,
        bookingSessionType?.url
    ]);

    const showTimeRange =
        !bookingSessionType?.duration && bookingSession?.startTime && bookingSession.endTime;

    return (
        <Flex
            flexDirection={{ base: 'column', lg: isConfirmation ? 'column' : 'row' }}
            alignItems={{ base: 'flex-start', lg: isConfirmation ? 'flex-start' : 'center' }}
            gap={{ base: '12px', lg: isConfirmation ? '16px' : '32px' }}
            fontWeight={600}
            color="darkText"
            whiteSpace="nowrap"
        >
            {isConfirmation
                ? bookingSession && (
                      <>
                          <Flex alignItems="center" gap="8px">
                              <CurrencyIcon />
                              <Text color="darkText">
                                  {t('confirmationPage.paid')}: {bookingSession.amountPaid}
                              </Text>
                          </Flex>
                          <Flex alignItems="center" gap="8px">
                              <CurrencyIcon />
                              <Text color="darkText">
                                  {t('confirmationPage.remainingBalance')}:{' '}
                                  {bookingSession.amountDue}
                              </Text>
                          </Flex>
                      </>
                  )
                : bookingSessionType && (
                      <Flex alignItems="center" gap="8px">
                          <CurrencyIcon />
                          <Text color="darkText">
                              {(bookingSession ?? bookingSessionType).price}
                          </Text>
                      </Flex>
                  )}
            {bookingSessionType?.duration && (
                <Flex alignItems="center" gap="8px">
                    <ClockIcon />
                    <Text color="darkText">
                        {bookingSessionType.duration} {durationString}
                    </Text>
                </Flex>
            )}
            {locationValue && (
                <Flex alignItems="center" gap="8px" whiteSpace="nowrap" overflowX="hidden">
                    {locationIcon}
                    <Text
                        color="darkText"
                        title={locationValue}
                        overflowX="hidden"
                        textOverflow="ellipsis"
                    >
                        {locationValue}
                    </Text>
                </Flex>
            )}
            {bookingSession?.startTime && (
                <Flex alignItems="center" gap="8px">
                    <CalendarIcon />
                    <Text color="darkText">
                        {bookingSession.startTime &&
                            new Date(bookingSession.startTime).toLocaleDateString([], {
                                dateStyle: 'full'
                            })}
                        {!showTimeRange &&
                            ` - ${new Date(bookingSession.startTime).toLocaleTimeString([], {
                                timeStyle: 'short'
                            })}`}
                    </Text>
                </Flex>
            )}
            {showTimeRange && (
                <Flex alignItems="center" gap="8px">
                    <ClockIcon />
                    <Text color="darkText">
                        {`${new Date(bookingSession.startTime).toLocaleTimeString([], {
                            timeStyle: 'short'
                        })} - ${new Date(bookingSession.endTime).toLocaleTimeString([], {
                            timeStyle: 'short'
                        })}`}
                    </Text>
                </Flex>
            )}
        </Flex>
    );
};

export default BookingSessionSummary;
