import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Flex, Text, useToast } from '@chakra-ui/react';
import styled from '@emotion/styled';
import ReCAPTCHA from 'react-google-recaptcha';
import DatePicker from 'ts/common/components/date-picker/DatePicker';
import { ButtonProps } from 'ts/common/components/gallery';
import { useBreakpoint, useTranslate } from 'ts/common/hooks';
import { useClientBookingExperience } from 'client_react/booking/ClientBookingExperience';
import MobileDateAndTimePage from 'client_react/booking/MobileDateAndTimePage';
import { ROUTE } from 'client_react/booking/common';
import BookingSessionHeading from 'client_react/booking/components/BookingSessionHeading';
import ResponsiveButtonGroup from 'client_react/booking/components/ResponsiveButtonGroup';
import { getFirstAvailableDate, setDateToUTC } from 'client_react/helpers';
import ReCaptchaDisclaimer from './components/ReCaptchaDisclaimer';
import TimePicker from './components/TimePicker';
import { getBrowserTimeZone } from './helpers';
import { isBookingSessionError } from './useBookingSession';

const Container = styled(Box)`
    .grecaptcha-badge {
        visibility: hidden;
    }
`;

type Props = { recaptchaKey?: string };

const DateAndTimePage = ({ recaptchaKey }: Props) => {
    const {
        bookingSession,
        bookingSessionType,
        bookingSessionTypeAvailability,
        createBookingSession,
        updateBookingSession,
        isPending,
        navigateWithSession
    } = useClientBookingExperience();

    const t = useTranslate('booking.dateAndTimePage');
    const isLargeScreen = useBreakpoint();
    const today = new Date();

    const errorToast = useToast({
        status: 'error'
    });

    const [selectedTimeslot, setSelectedTimeslot] = useState<string | undefined>();
    const [selectedDate, setSelectedDate] = useState<Date>();
    const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const formattedDate = selectedDate?.toISOString().split('T')[0];

    const availableTimeslots =
        (formattedDate &&
            bookingSessionTypeAvailability &&
            bookingSessionTypeAvailability[formattedDate]) ||
        [];

    const isAvailabilityEmpty =
        !bookingSessionTypeAvailability ||
        (Object.keys(bookingSessionTypeAvailability).length === 1 &&
            'status' in bookingSessionTypeAvailability);

    const handleDateChange = (date: Date | null) => {
        if (!date) {
            return;
        }

        setSelectedDate(date);
        setSelectedTimeslot(undefined);
    };

    const handleTimeslotChange = (timeslot: string) => {
        setSelectedTimeslot(timeslot);
    };

    const firstAvailableDate = useMemo(
        () =>
            bookingSessionTypeAvailability
                ? getFirstAvailableDate(bookingSessionTypeAvailability)
                : null,
        [bookingSessionTypeAvailability]
    );

    const handleContinueClick = useCallback(
        async (token: string) => {
            if (!bookingSessionType) {
                return;
            }

            if (bookingSession) {
                const response = await updateBookingSession({
                    sessionTypePublicId: bookingSessionType.publicId,
                    startTime: selectedTimeslot
                });

                if (isBookingSessionError(response)) {
                    errorToast({
                        title: t('apiError')
                    });
                } else {
                    navigateWithSession(ROUTE.CONTACT_INFORMATION);
                }
            } else {
                const response = await createBookingSession({
                    sessionTypePublicId: bookingSessionType.publicId,
                    startTime: selectedTimeslot,
                    recaptchaToken: token
                });

                if (!response || isBookingSessionError(response)) {
                    errorToast({
                        title: t('apiError')
                    });
                } else {
                    navigateWithSession(ROUTE.CONTACT_INFORMATION, response.publicId);
                }
            }
        },
        [
            bookingSession,
            bookingSessionType,
            createBookingSession,
            errorToast,
            navigateWithSession,
            selectedTimeslot,
            t,
            updateBookingSession
        ]
    );

    const handleCaptchaChange = useCallback(
        (token: string | null) => {
            setRecaptchaToken(token);

            if (token) {
                handleContinueClick(token);
            }
        },
        [handleContinueClick]
    );

    const triggerReCaptchaValidation = useCallback(() => {
        if (!recaptchaKey || recaptchaToken) {
            return;
        }

        recaptchaRef.current?.execute();
    }, [recaptchaKey, recaptchaToken]);

    useEffect(() => {
        if (firstAvailableDate) {
            handleDateChange(setDateToUTC(firstAvailableDate));
        }
    }, [firstAvailableDate]);

    useEffect(() => {
        if (isPending) {
            return;
        }

        if (bookingSession && !bookingSession.isIncomplete) {
            navigateWithSession(ROUTE.CONFIRMATION);
        }
    }, [bookingSession, isPending, navigateWithSession]);

    const buttons = useMemo<ButtonProps[]>(
        () => [
            {
                text: t('continue'),
                isDisabled: !selectedTimeslot || (!recaptchaRef.current && Boolean(recaptchaKey)),
                onClick: triggerReCaptchaValidation,
                isLoading: isPending
            }
        ],
        [triggerReCaptchaValidation, isPending, recaptchaKey, selectedTimeslot, t]
    );

    if (!bookingSessionType) {
        return null;
    }

    return (
        <Container>
            <BookingSessionHeading
                bookingSession={bookingSession}
                bookingSessionType={bookingSessionType}
            />
            {isLargeScreen ? (
                <>
                    <Flex direction="column" gap="24px">
                        <Text>{bookingSessionType.description}</Text>
                        <Flex flexDirection="row" gap="20px" flexWrap="nowrap" width="100%">
                            <Flex alignItems="flex-start" flexWrap="wrap">
                                {bookingSessionTypeAvailability && (
                                    <DatePicker
                                        inline
                                        centeredDate
                                        disableUnavailableDays
                                        onChange={handleDateChange}
                                        min={today}
                                        value={selectedDate ? selectedDate : null}
                                        availabilities={bookingSessionTypeAvailability}
                                    />
                                )}
                            </Flex>
                            <Flex direction="column">
                                {selectedDate && (
                                    <Flex
                                        alignItems="flex-start"
                                        direction="column"
                                        paddingTop="4px"
                                    >
                                        <Text
                                            fontSize="16px"
                                            fontWeight={600}
                                            fontFamily="open-sans, sans-serif"
                                        >
                                            {new Date(selectedDate).toLocaleDateString([], {
                                                weekday: 'long',
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric'
                                            })}
                                        </Text>
                                        <Text
                                            fontSize="12px"
                                            fontFamily="open-sans, sans-serif"
                                            fontWeight={400}
                                        >
                                            {getBrowserTimeZone()}
                                        </Text>
                                    </Flex>
                                )}

                                <Box
                                    flexWrap="wrap"
                                    height="400px"
                                    width="100%"
                                    overflowY="auto"
                                    sx={{
                                        '&::-webkit-scrollbar': {
                                            display: 'none'
                                        },
                                        msOverflowStyle: 'none', // For IE and Edge
                                        scrollbarWidth: 'none' // For Firefox
                                    }}
                                >
                                    {!isAvailabilityEmpty ? (
                                        <TimePicker
                                            selectedTimeslot={selectedTimeslot}
                                            onTimeslotChange={handleTimeslotChange}
                                            timeslots={availableTimeslots}
                                        />
                                    ) : (
                                        <Text
                                            paddingTop="10px"
                                            fontSize="16px"
                                            fontFamily="open-sans, sans-serif"
                                        >
                                            {t('noAvailabilities')}
                                        </Text>
                                    )}
                                </Box>
                            </Flex>
                        </Flex>

                        <ResponsiveButtonGroup buttons={buttons} />
                    </Flex>
                    <ReCaptchaDisclaimer />
                </>
            ) : (
                bookingSessionTypeAvailability && (
                    <MobileDateAndTimePage
                        availabilities={bookingSessionTypeAvailability}
                        bookingSessionType={bookingSessionType}
                        hideDatePicker={!!isAvailabilityEmpty}
                        onSubmit={triggerReCaptchaValidation}
                        selectedDate={selectedDate}
                        selectedTimeslot={selectedTimeslot}
                        onDateChange={handleDateChange}
                        onTimeslotChange={handleTimeslotChange}
                        timeslots={availableTimeslots}
                    />
                )
            )}
            {recaptchaKey && (
                <ReCAPTCHA
                    data-testid="booking-recaptcha-element"
                    onChange={handleCaptchaChange}
                    ref={recaptchaRef}
                    sitekey={recaptchaKey}
                    size={'invisible'}
                />
            )}
        </Container>
    );
};

export default DateAndTimePage;
